import React from "react";
import { navigate } from "gatsby";

export const ActionBanner = ({ page }) => {
  const handleActionType = ({ target: { value } }) => {
    if (value === "quotes") {
      navigate("/quotes");
    } else if (value === "troubleticket") {
      navigate("/troubleticket");
    } else if (value === "battery") {
      navigate("/battery");
    } else if (value === "tyres") {
      navigate("/tyre");
    } else if (value === "quickservice") {
      navigate("/quickservice");
    }
  };

  return (
    <div className="container">
      <div className="wrapper is-flex">
        <h3 className="has-text-white">What would you like to do?</h3>
        <div className="select is-medium">
          <select onChange={handleActionType}>
            <option defaultValue="">Click to select a service</option>
            <option
              value="quotes"
              selected={page === "quotes" ? "selected" : null}
            >
              Spare Part Quote
            </option>
            <option
              value="troubleticket"
              selected={page === "troubleticket" ? "selected" : null}
            >
              Car Trouble Ticket
            </option>
            <option
              value="battery"
              selected={page === "battery" ? "selected" : null}
            >
              Car Batteries
            </option>
            <option
              value="tyres"
              selected={page === "tyre" ? "selected" : null}
            >
              Car Tyres
            </option>
            <option
              value="quickservice"
              selected={page === "quickservice" ? "selected" : null}
            >
              Quick Home Service
            </option>
          </select>
        </div>
      </div>
    </div>
  );
};
