import React, { useState } from "react";
import { Link } from "gatsby";
import { IoIosClose } from "react-icons/io";
import scrollTo from "gatsby-plugin-smoothscroll";
import logo from "../../images/logo.png";

const Navbar = () => {
  const [nav, setNav] = useState(false);

  return (
    <div className="hero-head">
      <nav className="navbar is-transparent">
        <div className="container">
          <div className="navbar-brand is-centered">
            <a href="https://mycarviva.com" className="navbar-item">
              <img src={logo} alt="Logo-1" />
            </a>
            <a
              role="button"
              className="navbar-burger"
              data-target="navMenuu"
              aria-label="menu"
              aria-expanded="false"
              onClick={() => setNav(true)}
            >
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </a>
          </div>
          <div id="navbarMenuHeroA" className="navbar-menu">
            <div className="navbar-end is-uppercase">
              <Link
                href="https://mycarviva.com"
                className="navbar-item has-text-white"
              >
                Home
              </Link>
              <a
                className="navbar-item has-text-white"
                href="https://mycarviva.com/#how-it-works"
              >
                How it works
              </a>
              <div className="navbar-item has-dropdown is-hoverable">
                <a href="#" className="navbar-link has-text-white">
                  Services
                </a>
                <div className="navbar-dropdown">
                  <a
                    className="navbar-item"
                    href="https://lubricant.mycarviva.com"
                  >
                    Lubricants
                  </a>
                  <hr className="navbar-divider" />
                  <a
                    className="navbar-item"
                    href="https://autocare.mycarviva.com"
                  >
                    Auto Parts
                  </a>
                  <hr
                    className="navbar-divider"
                    href="https://autocare.mycarviva.com"
                  />
                  <a className="navbar-item">Autocare</a>
                </div>
              </div>
              <Link
                href="https://mycarviva.com/partners/"
                className="navbar-item has-text-white"
              >
                Autocare partners
              </Link>
              <Link
                href="https://mycarviva.com/contact/"
                className="navbar-item has-text-white"
              >
                Contact us
              </Link>
              <div className="navbar-item">
                <Link
                  href="https://mycarviva.com/partners/"
                  className="button is-primary is-medium is-inverted has-text-weight-semibold"
                >
                  get started
                </Link>
              </div>
              <div className="navbar-item">
                <a
                  href="https://sp.mycarviva.com/"
                  className="button is-white is-outlined is-medium has-text-weight-semibold"
                >
                  Login
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          className="navbar-menu mobile-menu is-hidden-desktop"
          id="navMenu"
          style={nav ? { display: "block" } : { display: "none" }}
        >
          <button id="closeNav" onClick={() => setNav(false)}>
            <IoIosClose color="#fff" size="3rem" />
          </button>
          <ul>
            <li>
              <a href="https://mycarviva.com">Home</a>
            </li>
            <li>
              <a to="/#how-it-works">How it works</a>
            </li>
            <div className="navbar-item has-dropdown is-hoverable">
              <a className="navbar-link has-text-white">Services</a>
              <div className="navbar-dropdown">
                <a
                  className="navbar-item"
                  href="https://lubricant.mycarviva.com"
                >
                  Lubricants
                </a>
                <hr className="navbar-divider" />
                <a
                  className="navbar-item"
                  href="https://autocare.mycarviva.com"
                >
                  Auto Parts
                </a>
                <hr
                  className="navbar-divider"
                  href="https://autocare.mycarviva.com"
                />
                <a className="navbar-item">Autocare</a>
              </div>
            </div>
            <li>
              <a href="https://mycarviva.com/partners/">Partners</a>
            </li>
            <li>
              <a href="https://mycarviva.com/contact/">Contact</a>
            </li>
            <li>
              <a
                href="https://mycarviva.com/partners/"
                className="btn-start has-text-primary"
              >
                Get Started
              </a>
            </li>
            <li>
              <a href="https://sp.mycarviva.com/" className="btn-login">
                Login
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
