import React, { useState } from "react";
import { Link } from "gatsby";
import {
  FaTwitter,
  FaInstagram,
  FaFacebookSquare,
  FaLinkedin,
} from "react-icons/fa";
import { StaticQuery, graphql } from "gatsby";
import logo from "../../images/logo.png";

const Footer = () => {
  const [modal, setModal] = useState(false);
  const [faqModal, setFaqModal] = useState(false);

  return (
    <StaticQuery
      query={graphql`
        query SocialQuery {
          site {
            siteMetadata {
              twitter
              facebook
              instagram
              linkedIn
            }
          }
        }
      `}
      render={(data) => (
        <>
          <footer className="footer is-relative">
            <div className="container">
              <div className="columns">
                <div className="column is-one-fifth">
                  <a href="https://mycarviva.com" className="logo">
                    <img src={logo} alt="logo" />
                  </a>
                </div>
                <div className="column">
                  <a
                    href="https://mycarviva.com/#how-it-works"
                    className="navbar-item has-text-white"
                  >
                    how it works
                  </a>
                  <a
                    href="https://mycarviva.com/partners/"
                    className="navbar-item has-text-white"
                  >
                    Autocare partners
                  </a>
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      setFaqModal(true);
                    }}
                    className="navbar-item has-text-white"
                  >
                    Faq
                  </a>
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      setModal(true);
                    }}
                    className="navbar-item has-text-white"
                  >
                    terms and conditions
                  </a>
                  <a
                    href="https://mycarviva.com/about/"
                    className="navbar-item has-text-white"
                  >
                    About us
                  </a>
                  <a
                    href="https://mycarviva.com/contact/"
                    className="navbar-item has-text-white"
                  >
                    contact us
                  </a>
                </div>
                <div className="column social is-one-quarter">
                  <span>Connect on Social Media</span>
                  <a
                    href={data.site.siteMetadata.facebook}
                    className="button is-text"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaFacebookSquare color="#fff" size={16} />
                  </a>
                  <a
                    href={data.site.siteMetadata.instagram}
                    className="button is-text"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaInstagram color="#fff" size={16} />
                  </a>
                  <a
                    href={data.site.siteMetadata.twitter}
                    className="button is-text"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaTwitter color="#fff" size={16} />
                  </a>
                  <a
                    href={data.site.siteMetadata.linkedIn}
                    className="button is-text"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaLinkedin color="#fff" size={16} />
                  </a>
                </div>
              </div>
            </div>
          </footer>
        </>
      )}
    />
  );
};

export default Footer;
